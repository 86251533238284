.Challenges {
    position: absolute;
    right: 10px;
    bottom: 10vh;
    display: flex;
    flex-direction: column;
    z-index: 100;
}

.ChallengeCard {
    background: #444;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0;
    overflow: hidden;
    box-shadow: 2px 5px 15px 0 rgba(0, 0, 0, 0.35);
}

.ChallengeCard-OppInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    background: #111;
    color: #aaa;
    margin: -10px -10px 0  -10px;
    padding: 10px 10px  5px 10px;
}

.ChallengeCard-OppName {
    font-size: 1.3rem;
    font-weight: bold;
}

.ChallengeCard-OppRating {
    margin-top: 2px;
    font-size: 1rem;
}

.ChallengeCard-MatchInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.ChallengeCard-ColorLabel {
    margin-right: 10px;
}


.ChallengeCard-MatchInfo b {
    font-weight: bold;
}

.ChallengeCard-InboundControls {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.ChallengeCard button {
    border: none;
    padding: .3rem .6rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.05rem;
    font-weight: bold;
    box-shadow: 1px 3px 8px rgba(0,0,0,.25);
}

.ChallengeCard-Accept {
    background: #0c5;
    color: #042;
}

.ChallengeCard-Accept:hover {
    background: #0a3;
}

.ChallengeCard-Decline {
    background: #422;
    margin-left: 1.5rem;
    color: #c66;
}

.ChallengeCard-Decline:hover {
    background: #522;
}

.ChallengeCard-OutboundControls {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.ChallengeCard-Revoke {
    background: #bbb;
    margin-top: 10px;
}

.ChallengeCard-Revoke:hover {
    background: #999;
}