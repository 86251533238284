.Clock {
    width: 6.5rem;
    border-radius: .5rem;
    padding: .5rem 0;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

.WhiteClock {
    background-color: #ddd;
    color: black;
}

.BlackClock {
    background-color: black;
    color: white;
}

.Clock.LowTime {
    background-color: red;
}

@media (max-width: 767px) {
    .Clock {
        width: auto;
        border-radius: .5rem;
        padding: .75rem 1rem;
        padding-bottom: 0.5rem;
        font-size: 1.75rem;
        font-weight: bold;
        text-align: center;
    }
}