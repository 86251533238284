.Tile {
    position: relative;
    width: 12.5%;
    height: 12.5%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.Tile.Interactable {
    cursor: pointer;
}

.Tile.DarkSquare {
    background-color: #203b58;
}

.Tile.LightSquare {
    background-color: #669cd5;
}

.Tile svg, .Tile img {
    width: 100%;
    height: 100%;
    padding: 2.5%;
    fill: white;
    user-select: none;
    position: absolute;
    z-index: 15;
}

.Tile .BlackPawn,
.Tile .BlackRook,
.Tile .BlackKnight,
.Tile .BlackBishop,
.Tile .BlackQueen,
.Tile .BlackKing {
    fill: black;
}

.Tile.Checked img {
    background: radial-gradient(circle, rgba(255,0,0,1) 0%, rgba(255,0,0,0) 100%);
}

.Tile .TileDot {
    width: 25%;
    height: 25%;
    background-color: white;
    position: absolute;
    z-index: 18;
    opacity: 70%;
    border-radius: 20%;
    box-shadow: 0 0 5px 1px black;
}

.Tile .Highlight {
    position: absolute;
    z-index: 12;
    width: 100%;
    height: 100%;
}

.Tile.LastMoveStart .Highlight {
    opacity: 30%;
    background-color: orange;
}

.Tile.LastMoveEnd .Highlight{
    opacity: 50%;
    background-color: orange;
}

.Tile.Selected .Highlight {
    opacity: 30%;
    background: radial-gradient(circle, rgba(255,255,255,1) 20%, rgba(255,255,255,.25) 80%)
}

.Tile.Selected.LightSquare .Highlight {
    opacity: 70%;
}

.Tile.Premove .Highlight {
    opacity: 70%;
    background: red;
}

.Tile.Premove.LightSquare .Highlight {
    opacity: 70%;
    background: red;
}

.Tile.Dragging img {
    visibility: hidden;
}

.AnimTile {
    position: absolute;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

#DraggingTile Img {
    user-select: none;
}

.AnimTile svg, .AnimTile img {
    width: 100%;
    height: 100%;
    padding: 2.5%;
    fill: white;
    user-select: none;
    position: absolute;
    z-index: 15;
}
