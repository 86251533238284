.Dots {
    position: relative;
    width: 100%;
    height: 20%;
}

.Dot {
    position: absolute;
    background: white;
    border-radius: 10%;
}