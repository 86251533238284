.BotDroplistItem {
    color: rgba(255,255,255, 0.7);
    width: 100%;
    padding: 10px 2.5vw;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-radius: .5rem;
}

.BotDroplistItem:hover {
    border-right-color: black;
    border-right-width: 5px;
}

.BotDroplistItem.Selected {
    background: rgba(255, 255, 255, .5);
    color: white;
}

.BotDroplistItem-SelectedDot {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
}

.BotDroplistItem-SelectedDot.Selected {
    background: white;
}

.BotDroplistItem-Nametag {
    margin-right: 1rem;
    margin-left: .5rem;
    flex-grow: 1;
    text-align: center;
    line-height: 1.5rem;
    font-size: 1.5rem;
}