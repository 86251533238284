.Notifs {
    position: absolute;
    top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Notif {
    padding: 10px 15px;
    margin-top: 0.5rem;
    border-radius: 5px;
    background: white;
    color: black;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
}

.Notif.Error {
    background: red;
    color: white;
}

.Notif.Warn {
    background: #BBBB44;
    color: #333;
}