.BotMatchPicker {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 25rem;
    padding: 4vw;
    background: linear-gradient(#78593c, #a25f20);
    border-radius: 20px;
}

.BotMatchPicker-Back {
    position: absolute;
    width: 2rem;
    height: 2rem;
    left: 1rem;
    top: 2rem;
    opacity: 0.7;
}

.BotsDroplist {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(0,0,0, 0.3);
    box-shadow: 0 2px 8px inset rgba(0,0,0, 0.2);
    border-radius: 1rem;
    padding: 1rem;
}

.BotMatchPicker button {
    color: #420;
}

@media (max-width: 767px) {
    .BotMatchPicker {
        padding: 10vw;
        height: 70vh;
        width: 100%;
    }

    .BotMatchPicker h2 {
        font-size: 2.5rem;
    }
}
