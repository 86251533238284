.Header {
    width: 100vw;
    height: 8vh;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    cursor: pointer;
}

.Header-Identifiers {
    display: flex;
    align-items: center;
}

#HeaderLogo {
    width: 5vh;
    height: 5vh;
    margin-right: 1rem;
}

#HeaderTitle {
    color: #aaa;
}