.SummaryFrame {
    position: absolute;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Summary {
    background: #333;
    width: 300px;
    height: 300px;
    border-radius: 1vw;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, .7);
}

.Summary-Banner {
    background: #222;
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}

.Summary-Banner p {
    color: white;
    font-weight: bold;
}

.Summary-Body {
    width: 100%;
    height: 75%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}

.Summary-Body p {
    color: white;
    font-size: 1.5rem;
}

.Summary-Buttons {
    width: 90%;
    display: flex;
    justify-content: space-around;
}

.Summary-Buttons button {
    border: none;
    width: 42%;
    padding: 5%;
    border-radius: .25rem;
    color: white;
    font-size: 1.25rem;
    font-weight: bold;
    text-shadow: 0 0 2px black;
    cursor: pointer;
}

.Summary-Buttons button:first-child {
    background: #229999;
    border-bottom: 2px solid #116666;
}

.Summary-Buttons button:first-child:hover{
    background: #30cccc;
    border-bottom: 2px solid #119999;
}

.Summary-Buttons button:last-child {
    background: #aa7722;
    border-bottom: 2px solid #774411;
}

.Summary-Buttons button:last-child:hover {
    background: #ddaa33;
    border-bottom: 2px solid #aa7722;
}
