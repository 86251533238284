.DisconnectedOverlay {
    position: absolute;
    z-index: 1000;
    width: 100vw;
    height: 100vh;

    background: rgba(0,0,0, .5);
    backdrop-filter: blur(5px);

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;
    pointer-events: none;
}

/*@keyframes disconnectedOverlayFadeIn {*/
/*    from { opacity: 0;}*/
/*    to { opacity: 1;}*/
/*}*/
/*@keyframes disconnectedOverlayFadeOut {*/
/*    from { opacity: 1;}*/
/*    to { opacity: 0;}*/
/*}*/

.DisconnectedOverlay.Visible {
    opacity: 1;
    /*animation: disconnectedOverlayFadeIn 1s;*/

    pointer-events: all;
}

.DisconnectedOverlay.Hidden {
    opacity: 0;
    /*animation: disconnectedOverlayFadeOut 1s;*/

    pointer-events: none;
}

.DisconnectedOverlay p {
    color: white;
    font-size: 4rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 8rem;
}