.BoardFrame {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BoardWrapped {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.BoardLeftGutter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 75vh;
}

.LeftGutter-Bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.NameTag {
    margin-left: 1.5rem;
    color: white;
    font-size: 2rem;
}

.Board {
    background-color: red;
    display: flex;
    flex-wrap: wrap;
    aspect-ratio: 1;
    border: 4px solid white;
    width: 75vh;
    height: 75vh;
    margin: 10px;
}

.BoardBottomGutter, .BoardTopGutter {
    width: 100%;
    height: 1.5rem;
    padding-left: 2rem;
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;
}

.BoardTopGutter {
    align-items: flex-end;
}

.BoardBottomGutter {
    align-items: start;
}

.PlayerName {
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
}

.LostPieces img {
    width: 3rem;
    height: 3rem;
    margin-left: -0.5rem;
}

@media (max-width: 767px) {
    .Board {
        width: 95vw;
        height: 95vw;
    }

    .BoardFrame {
        flex-direction: column;
        justify-content: start;
        padding-top: 3rem;
    }

    .BoardTopGutter, .BoardBottomGutter {
        flex-direction: column;
        height: auto;
        padding-left: 1rem;
    }

    .BoardTopGutter-Top, .BoardTopGutter-Bottom, .BoardBottomGutter-Top, .BoardBottomGutter-Bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .BoardBottomGutter-Bottom, .BoardTopGutter-Bottom {
        margin-top: 0.5rem;
    }

    .PlayerName {
        font-size: 1.75rem;
    }

    .CapturedPiece {
        margin-left: -0.5rem;
    }

    .CapturedPiece.Pawn {
        margin-left: -1.5rem;
    }
}