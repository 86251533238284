.Button {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PlayButton {
    background: rgb(255, 255, 255, 0.25);
    border: none;
    border-radius: 10px;
    font-size: 2rem;
    width: 180px;
    padding: 5px 0;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.PlayButton:hover {
    background: rgb(255, 255, 255, .5);
}

.CancelMatchmakingButton {
    background: #888;
    border: none;
    border-radius: 10px;
    font-size: 2rem;
    width: 180px;
    padding: 5px 0;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.CancelMatchmakingButton:hover {
    background: #999;
}

.ResignButton {
    width: 7.5rem;
    border-radius: .5rem;
    padding: .5rem 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    background-color: #444;
    color: white;
    border: none;
    cursor: pointer;
    margin: 10px 0;
}

.ResignButton-Unlocked {
    background: #c00;
}

@media (max-width: 767px) {
    .ResignButton {
        width: 6.5rem;
        border-radius: .5rem;
        padding: .5rem 0;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
    }
}