.HumanMatchPicker {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 25rem;
    padding: 4vw;
    background: linear-gradient(#3c5978, #1b5da4);
    border-radius: 20px;
}

.HumanMatchPicker h2 {
    color: white;
    font-size: 3rem;
}

.HumanMatchPicker-MatchmakingMetrics {
    display: flex;
    flex-direction: column;
}

.HumanMatchPicker-MatchmakingMetrics p {
    font-size: 1.25rem;
    color: #aaa;
    align-self: start;
    padding: 1rem;
}

.HumanMatchPicker-Back {
    position: absolute;
    width: 2rem;
    height: 2rem;
    left: 1rem;
    top: 2rem;
    opacity: 0.7;
}

#EloTag, #WinstreakTag {
    font-size: 1.75rem;
    color: #ccc;
    font-weight: 800;
    align-self: end;
    background: rgba(0,0,0, 0.3);
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px inset rgba(0,0,0, 0.2);
}

.HumanMatchPicker button {
    color: #024;
}

.TimerIcon {
    width: 3rem;
    height: 3rem;
    opacity: 0.3;
    margin: 0.5rem;
}

.TimeControls .Divider {
    width: 3px;
    height: 3rem;
    background: white;
    opacity: 0.3;
    margin: 0.5rem;
}

.TimeControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem;
    background: rgba(0,0,0,.3);
    border-radius: 0.8rem;
}

.HumanMatchPicker .TimeControls {
    box-shadow: 0 2px 8px inset rgba(0,0,0, 0.2);
}

.MatchmakingContext {
    font-size: 1.5rem;
    color: #ddd;
}

@media (max-width: 767px) {
    .HumanMatchPicker {
        padding: 10vw;
        height: 70vh;
        width: 100%;
    }

    .HumanMatchPicker h2 {
        font-size: 2.5rem;
    }
}
